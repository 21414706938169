.Table__itemCount {
  /* margin-top: 10px; */
  font-size: 14px;
}

.Table__pagination {
  display: inline-flex;
  justify-content: flex-end;
  padding: 20px 260px;
}

.Table__pageButton {
  font-size: 15px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: gray;
}

.Table__pageButton--active {
  color: #45b3e3;
  font-weight: bold;
}

.pagination-input {
  border-radius: 14px;
  border: 1px solid rgba(220,221,221,1);
  width: 27px;
  height: 27px;
  text-align: center;
}

.pagination-input:focus{
  outline: none;
}