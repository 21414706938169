.slider-container {
    height: 45vh;
}

.each-slide .image-container {
  display: flex;
  justify-content: center;
}

.each-slide .about-image {
    height: 40vh;
    width: 75%;
    background-size: contain;
    background-repeat: no-repeat;
}

.each-slide .about-pie-image{
    height: 37vh;
    margin-top: 3vh;
    width: 75%;
    background-size: contain;
    background-repeat: no-repeat;
}

.image-text {
    display: flex;
    justify-content: center;
    margin: 0px 0px 5px 0px;
}

.each-slide .image-text span {
    text-align: center;
    background-color: #F6F6F6FF;
}
 
.slider-container .react-slideshow-container .nav {
    background: rgba(0, 165, 181, 0.7); 
    margin: 0px 10px;
}

.slider-container .react-slideshow-container .nav:hover {
    background: #0097A5;
}

.slider-container .react-slideshow-container .nav span {
    border-color: white;
}


