.slider-container {
    height: 45vh;
}

.each-slide .image-container {
  display: flex;
  justify-content: center;
}

.each-slide .about-image {
    height: 40vh;
    width: 75%;
    background-size: contain;
    background-repeat: no-repeat;
}

.each-slide .about-pie-image{
    height: 37vh;
    margin-top: 3vh;
    width: 75%;
    background-size: contain;
    background-repeat: no-repeat;
}

.image-text {
    display: flex;
    justify-content: center;
    margin: 0px 0px 5px 0px;
}

.each-slide .image-text span {
    text-align: center;
    background-color: #F6F6F6FF;
}
 
.slider-container .react-slideshow-container .nav {
    background: rgba(0, 165, 181, 0.7); 
    margin: 0px 10px;
}

.slider-container .react-slideshow-container .nav:hover {
    background: #0097A5;
}

.slider-container .react-slideshow-container .nav span {
    border-color: white;
}



/* ********* General ********* */
/* import web font: Noway */
@font-face {
  font-family: "noway-regular-webfont";
  src: url(/static/media/noway-regular-webfont.d8ebab6d.eot);
  src: 
    url(/static/media/noway-regular-webfont.506a9d0f.woff) format("woff"),
    url(/static/media/noway-regular-webfont.f9fbb43a.woff2) format("woff2"),
    url(/static/media/noway-regular-webfont.1a3f17c8.ttf) format("truetype"),
    url(/static/media/noway-regular-webfont.74da9750.svg) format("svg");
}

* {
  font-family: 'noway-regular-webfont', Arial, sans-serif;
  font-weight: normal !important;
}

.header-name {
  font-size: 25px;
  color: rgba(70,70,70,1);
  line-height: 37px;
  -webkit-text-stroke: 2px rgba(70,70,70,1);
}

.gray-bg {
  background-color: #F6F6F6FF;
}

/* business, period filter label */
.filter-label {
  font-size: 15px !important;
  -webkit-text-stroke: 1px rgba(70,70,70,1);
}

/* Overview statistics li */
.overview-statistic {
  margin-top: 10px !important;
  line-height: 30px !important;
  margin-left: 5px !important;
}

/* piechart header name */
.piechart-header {
  font-size: 15px !important;
  -webkit-text-stroke: 1px rgba(70,70,70,1);
  padding-top: 20px;
  padding-left: 30px
}

/* piechart select item name (after brand and category filter) */
.piechart-selectedname {
  font-size: 15px;
  line-height: 30px;
  margin-left: 5px;
  padding-top: 10px;
}

/* dropdown */
.dropdown-rounded {
  border-radius: 15px !important;
  border: 1px solid rgba(220,221,221,1) !important;
  background: rgba(255,255,255,1) !important;  
}

/* btn: login button */
.btn-login {
  width: 120px;
  height: 50px;
  color: rgba(255,255,255,1) !important;
  background:rgba(0,199,194,1) !important;
  border-radius: 30px !important;
  font-size: 20px !important; 
}

/* btn: [Report] apply business and period */
.btn-apply {
  background: rgba(0,199,194,1) !important;
  border-radius: 30px !important;
  color: rgba(255,255,255,1) !important;
  -webkit-text-stroke: 1px rgba(255,255,255,1) !important;
  font-size: 15px !important; 
}

/* Overview statistic, piechart content */
/* 點選tap後顯示的內容 */
.tap-content {
  margin-left: 20px !important; 
  margin-right: 14px !important; 
}

/* (segment) 拿掉border line */
.segment-none-border {
  border: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

/************************/
/*       LoginPage      */
/************************/
.login-form {
  background: rgba(255,255,255,1);
  overflow: scroll;
}

.login-page {
  background-image: url(/static/media/bg.2478a0a7.png) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100%;
}

/* forget password */
.login-forget-pwd {
  color:rgba(255,203,107,1);
  position: absolute;
  right: 0px;
}

/* footer side */
.footer {
  width:1920px;
  height:164px;
  position: relative !important;
  background: rgba(0,165,181,1) !important;
  color: white !important;
}

.contact-content {
  width: 269px;
  height: 56px;
  font-size: 14px;
  line-height: 18px;
}

/************************/
/*       SideMenu       */
/************************/
.sideMenu {
  color: rgba(255,255,255,1);
  line-height: 16px !important;
  font-size: 13px !important;
  background: #00A5B5 !important;
}

.username {
  font-size: 16px;
  color: rgba(255,255,255,1);
  line-height: 19px;
}

.business-username {
  margin-top: -12px;
  font-size: 10px;
  color: rgba(255,255,255,1);
  line-height: 13px;
}

.sideMenu {
  color: rgba(255,255,255,1);
  line-height: 16px !important;
  font-size: 13px !important;
  background: #00A5B5 !important;
}

.sideMenu-logout {
  color: #FFE783;
  background-color: #00A5B5;
  font-size: 10px;
  border-radius: 30px;
  border: 2px solid #FFE783;
  margin-left: 120px;
  margin-top: -10px;
  padding-left: 8px;
}

.sideMenu-logout:hover {
  color: #00A5B5;
  background-color: #FFE783;
}

.sideMenu-logout:active {
  color: #00A5B5;
  background-color: #DEC970;
}

/* Logo: logo_locofolio_white */
.sideMenu-footer-logo {
  position: absolute;
  right: 0%;
  bottom: 0%;
  margin-bottom: 29px;
  margin-right: 39px;
}

/************************/
/* override Semantic UI */
/* LoginPage */
/************************/

/* add white color */
.ui.header.white {
  color: rgba(255,255,255,1);
}

.ui.form input[type="text"] , 
.ui.form input[type="password"] {
  border: none;
  border-bottom: 2px solid #00C7C2;
  color: #00C7C2;
}

.ui.form .field>label {
  color: #00C7C2;
  text-align: left;
}

.ui.checkbox {
  color: #00C7C2;
}

.ui.checkbox label, 
.ui.checkbox label:hover, 
.ui.checkbox label:before, 
.ui.checkbox label:after {
  color: #00C7C2;
}

.ui.checkbox input:focus~label {
  color: #00C7C2;
}

.ui.checkbox input:checked~.box:after, 
.ui.checkbox input:checked~label:after {
  color: white !important; /* check icon's color */
  background-color: #00C7C2;
  font-size: 13px;
}

.ui.checkbox .box:before,
.ui.checkbox label:before,
.ui.checkbox .box:after, 
.ui.checkbox label:after {
  border-radius: 1.214286rem;  /* shape of checkbox */
}

.ui.header,
.ui.button {
  font-family: "noway-regular-webfont", 'Lato';
}

/************************/
/* override Semantic UI */
/* SideMenu */
/************************/

/* 滑過 */
.ui.inverted.menu a.item:hover,
.ui.link.inverted.menu .item:hover {
  background: #0097A5;
}

/* 按下 */
.ui.inverted.menu a.item:active,
.ui.link.inverted.menu .item:active {
  background: #008693;
}

/* 選定 */
.ui.inverted.menu .active.item {
  background: #00C7C2;
  -webkit-text-stroke:1px rgba(255,255,255,1);
}

/************************/
/* override Semantic UI */
/* tabular menu */
/************************/

.ui.tabular.menu .item {
  background: 1 1;
  background-color: rgba(220,221,221,1);
  color: white;
}

.ui.tabular.menu .active.item {
  background: none #00A5B5;
  color: white;
}

.ui.tabular.menu .item:hover {
  background-color: #00A5B5;
  color: white;
}

.ui.tabular.menu {
  border-bottom: 1px solid #00A5B5;
}

.ui.menu:last-child {
  margin-bottom: 24px;
  margin-left: 20px;
  margin-right: 10px;
}

/************************/
/* override react-table */
/************************/

.ReactTable .rt-thead .rt-th.-sort-asc {
  box-shadow: inset 0 3px 0 0 #00A5B5;
}

.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #00A5B5;
}

.ReactTable .rt-thead.-filters input {
  border-radius: 14px;
  border: 1px solid rgba(220,221,221,1);
  background: rgba(220,221,221,1);
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: #F6F6F6 !important;
}

.Table__itemCount {
  /* margin-top: 10px; */
  font-size: 14px;
}

.Table__pagination {
  display: inline-flex;
  justify-content: flex-end;
  padding: 20px 260px;
}

.Table__pageButton {
  font-size: 15px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: gray;
}

.Table__pageButton--active {
  color: #45b3e3;
  font-weight: bold;
}

.pagination-input {
  border-radius: 14px;
  border: 1px solid rgba(220,221,221,1);
  width: 27px;
  height: 27px;
  text-align: center;
}

.pagination-input:focus{
  outline: none;
}
